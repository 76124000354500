<template>
  <div class="echarts-container">
    <div :id="Echartsdata.id" class="line-echarts"></div>
    <date-block class="date-block" :active="active" cpn="TVOC"></date-block>
  </div>
</template>

<script>
import { DateBlock } from "../layout";
export default {
  name: "LineEcharts",
  components: {
    DateBlock,
  },
  props: {
    Echartsdata: Object,
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      myChart4: {},
    };
  },
  mounted() {
    //调用echart
    // console.log(this.Echartsdata);
    this.initStates();
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myChart4);
  },
  methods: {
    TVOCcolor(a) {
      console.log(a.nowValue);
      if (a.nowValue < 500) {
        return `{a| 优 }`;
      }
      if (a.nowValue > 500 && a.nowValue <= 600) {
        return `{b| 良 }`;
      }
      if (a.nowValue > 600 && a.nowValue <= 1000) {
        return `{c| 轻度 }`;
      }
      if (a.nowValue > 1000 && a.nowValue <= 2000) {
        return `{d| 中度 }`;
      }
      if (a.nowValue > 2000) {
        return `{e| 重度 }`;
      }

      // if(a.name=="CO2"){

      // }
    },
    initStates() {
      this.myChart4 = this.$echarts.init(
        document.getElementById(this.Echartsdata.id)
      );
      // console.log(111);
      let option = {
        title: [
          {
            text: this.Echartsdata.name,
            left: "15px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            text: this.Echartsdata.nowValue + "mg/m³",
            right: "55px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            subtext: this.TVOCcolor(this.Echartsdata),

            right: "16px",
            top: "4.5px",
            height: "20px",
            subtextStyle: {
              color: "#ffffff",
              fontSize: "13px",
              right: "20px",
              top: "17px",
              //height: "20px",
              lineOverflow: "none",
              rich: {
                a: {
                  backgroundColor: "#86c82a",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 25,
                  //verticalAlign: "middle",
                  align: "center",
                },
                b: {
                  backgroundColor: "#43bf58",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 25,
                  //verticalAlign: "middle",
                  align: "center",
                },
                c: {
                  backgroundColor: "#ffb246",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                d: {
                  backgroundColor: "#fe6718",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                e: {
                  backgroundColor: "#dd3330",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                f: {
                  backgroundColor: "#bd034e",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
              },
            },
          },
        ],
        grid: {
          top: "25%",
          right: "10%",
          left: "10%",
          bottom: "20%",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.Echartsdata.x,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: this.Echartsdata.name,
            data: this.Echartsdata.y,
            type: "line",
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#39debf",
                  },
                  {
                    offset: 1,
                    color: "#ffffff",
                  },
                ]),

                //shadowColor: "rgba(0,202,149, 0.9)",
                //shadowBlur: 20,
              },
            },
            itemStyle: {
              normal: {
                color: "#39debf", //改变折线点的颜色
                lineStyle: {
                  color: "#39debf", //改变折线颜色
                },
              },
            },
          },
        ],
      };
      this.myChart4.setOption(option);
    },
    // setHumidity(index) {
    //   if (index == 1) {
    //     this.humidityValue = 40;
    //     this.myChart.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   } else if (index == 2) {
    //     this.humidityValue = 70;
    //     this.myChart2.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   }
    // },
    // updateHumidity() {},
  },
  watch: {
    Echartsdata: {
      handler() {
        this.initStates();
        console.log(this.Echartsdata, "echarts");
      },
      deep: true,

      // this.option.series[0].data = this.Echartsdata.y;
      // this.myChart5.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
.echarts-container {
  position: relative;
}
.line-echarts {
  // width: auto;
  //width: 300px;
  width: 100%;
  height: 100%;
  //height: 350px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  background-color: #ffffff;
  text-align: center;

  position: relative;
  padding-bottom: 10px;
}
.date-block {
  position: absolute;
  bottom: 1px;
  left: 30%;
}
</style>
