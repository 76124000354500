<template>
  <div class="filter-container">
    <span class="filter-title">滤网状态</span>
    <div class="filter-block">
      <div class="filter-block-title">
        <span>初效滤网</span>
        <span>每个月更换,剩余时间</span>
        <span :style="{ color: formatColor(primaryFilterP) }">{{
          parseInt(filterData.primaryFilter / 3600)
        }}</span>
        <span>小时</span>
      </div>
      <div class="filter-progress">
        <el-progress
          :stroke-width="30"
          :percentage="primaryFilterP"
          :format="showText"
          :color="formatColor(primaryFilterP)"
        ></el-progress>
        <div class="progress-reset">
          <span>复位</span>
          <div
            :class="
              primaryFilterP <= 5
                ? 'filter-progress-btn'
                : 'filter-progress-btn-none'
            "
            @click="resetFilter(1, 'primaryFilterP')"
          >
            <img src="../../assets/images/fw.png" alt="" />
          </div>
        </div>
      </div>
      <div class="filter-desc">
        初效滤网主要用于过滤 5μm 以上颗粒,过滤效率可达90%，可有效过滤......
      </div>
      <!--      <div class="filter-remain">初效滤网已更换10次</div>-->
    </div>
    <div class="filter-block">
      <div class="filter-block-title">
        <span>中效滤网</span>
        <span>每个月更换,剩余时间</span>
        <span :style="{ color: formatColor(mediumFilterP) }">{{
          parseInt(filterData.mediumFilter / 3600)
        }}</span>
        <span>小时</span>
      </div>
      <div class="filter-progress">
        <el-progress
          :stroke-width="30"
          :percentage="mediumFilterP"
          :format="showText"
          :color="formatColor(mediumFilterP)"
        ></el-progress>
        <div class="progress-reset">
          <span>复位</span>
          <div
            :class="
              mediumFilterP <= 5
                ? 'filter-progress-btn'
                : 'filter-progress-btn-none'
            "
            @click="resetFilter(2, 'mediumFilterP')"
          >
            <img src="../../assets/images/fw.png" alt="" />
          </div>
        </div>
      </div>
      <div class="filter-desc">
        中效滤网主要用于过滤 5μm 以上颗粒,过滤效率可达90%，可有效过滤......
      </div>
      <!--      <div class="filter-remain">中效滤网已更换10次</div>-->
    </div>
    <div class="filter-block">
      <div class="filter-block-title">
        <span>高效滤网</span>
        <span>每个月更换,剩余时间</span>
        <span :style="{ color: formatColor(highFilterP) }">{{
          parseInt(filterData.highFilter / 3600)
        }}</span>
        <span>小时</span>
      </div>
      <div class="filter-progress">
        <el-progress
          :stroke-width="30"
          :percentage="highFilterP"
          :format="showText"
          :color="formatColor(highFilterP)"
        ></el-progress>
        <div class="progress-reset">
          <span>复位</span>
          <div
            :class="
              highFilterP <= 5
                ? 'filter-progress-btn'
                : 'filter-progress-btn-none'
            "
            @click="resetFilter(3, 'highFilterP')"
          >
            <img src="../../assets/images/fw.png" alt="" />
          </div>
        </div>
      </div>
      <div class="filter-desc">
        高效滤网主要用于过滤 5μm 以上颗粒,过滤效率可达90%，可有效过滤......
      </div>
      <!--      <div class="filter-remain">高效滤网已更换10次</div>-->
    </div>
    <width-dialog
      :property="property"
      width="350px"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
    >
      <div class="filter-dialog-reset">
        <div>
          {{ currentFilterN }}: 剩余
          <span style="color: #e83934">{{ currentFilterP }}%</span>
          剩余时间：<span style="color: #e83934">{{ currentFilterH }}</span
          >小时
        </div>
        <div>如您尚未更换滤网, 请及时更换滤网!</div>
        <div>如果您已更换滤网，请点击复位!</div>
      </div>
    </width-dialog>
  </div>
</template>

<script>
import WidthDialog from "../dialog/WidthDialog";
export default {
  name: "FilterState",
  components: {
    WidthDialog,
  },
  data() {
    return {
      filterData: {},
      property: {
        show: false,
        title: "滤网管理",
      },
      currentFilter: 0,
      currentFilterP: 0,
      currentFilterH: 0,
      currentFilterN: "",
    };
  },
  computed: {
    primaryFilterP() {
      return (
        parseInt(
          (this.filterData.primaryFilter * 100) /
            this.filterData.primaryFilterSum
        ) || 0
      );
    },
    mediumFilterP() {
      return (
        parseInt(
          (this.filterData.mediumFilter * 100) / this.filterData.mediumFilterSum
        ) || 0
      );
    },
    highFilterP() {
      return (
        parseInt(
          (this.filterData.highFilter * 100) / this.filterData.highFilterSum
        ) || 0
      );
    },
  },
  methods: {
    dialogConfirm() {
      const num = this.currentFilter;
      if (!num) return;
      if (num === 1 && this.primaryFilterP > 5) {
        return;
      }
      if (num === 2 && this.mediumFilterP > 5) {
        return;
      }
      if (num === 3 && this.highFilterP > 5) {
        return;
      }
      let params;
      if (num === 1) {
        params = { attributeKey: "primaryFilter", filterValue: 15552000 };
      } else if (num === 2) {
        params = { attributeKey: "mediumFilter", filterValue: 15552000 };
      } else {
        params = { attributeKey: "highFilter", filterValue: 15552000 };
      }
      params.entityId = this.$route.params.asset;
      this.$http.filterReset(params).then(() => {
        this.getData(params.entityId);
        this.property.show = false;
        this.$message({
          message: "复位成功",
          type: "success",
        });
      });
    },
    dialogCancel() {
      this.property.show = false;
    },
    showText(p) {
      return `剩余${p}%`;
    },
    getData(deviceId) {
      this.$http.getDeviceFilter(deviceId).then((res) => {
        console.log(res, "filter");
        this.filterData = res.data;
      });
    },
    formatColor(num) {
      if (num <= 5) {
        return "#e73838";
      }
      if (num >= 30) {
        return "#41c057";
      }
      return "#f7b423";
    },
    resetFilter(num, target) {
      this.currentFilter = num;
      if (this[target] > 5) return;
      if (num === 1) {
        this.currentFilterN = "初效滤网";
        this.currentFilterP = this.primaryFilterP;
        this.currentFilterH = parseInt(this.filterData.primaryFilter / 3600);
      } else if (num === 2) {
        this.currentFilterN = "中效滤网";
        this.currentFilterP = this.mediumFilterP;
        this.currentFilterH = parseInt(this.filterData.mediumFilter / 3600);
      } else {
        this.currentFilterN = "高效滤网";
        this.currentFilterP = this.highFilterP;
        this.currentFilterH = parseInt(this.filterData.highFilter / 3600);
      }

      this.property.show = true;
    },
  },
  created() {
    const deviceId = this.$route.params.asset;
    this.getData(deviceId);
  },
  watch: {
    "$route.params.asset"(newValue) {
      this.getData(newValue);
    },
  },
};
</script>

<style scoped lang="scss">
.filter-container {
  width: 340px;
  height: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .filter-title {
    font-size: 20px;
    color: #333;
  }
  .filter-block {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    .filter-block-title {
      span {
        font-size: 12px;
        color: #666;
        &:first-child {
          font-size: 16px;
          color: #333;
        }
        &:nth-child(2) {
          margin-left: 8px;
        }
      }
      .filter-h {
        color: #41c057;
      }
      .filter-m {
        color: #f7b423;
      }
      .filter-l {
        color: #e73838;
      }
    }
    .filter-progress {
      display: flex;
      //align-items: center;
      position: relative;
      ::v-deep.el-progress {
        margin-top: 10px;
        position: relative;
        width: 87%;
        .el-progress-bar__outer {
          background-color: #999;
        }
        .el-progress__text {
          font-size: 12px !important;
          position: absolute;
          left: 5%;
          top: 10px;
          color: #fff;
        }
        .el-progress-bar {
          padding-right: 20px;
        }
      }
      .progress-reset {
        display: flex;
        flex-direction: column;

        & > span {
          font-size: 12px;
          color: #666;
          margin-left: 5px;
          position: absolute;
          top: -14px;
          //right: 34px;
        }
        .filter-progress-btn {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #41c057;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: absolute;
          top: 6px;
          //right: 30px;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .filter-progress-btn-none {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #eaeaea;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 6px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .filter-desc {
      width: 100%;
      margin-top: 10px;
      font-size: 12px;
      color: #999;
    }
    .filter-remain {
      margin-top: 10px;
      font-size: 12px;
      color: #666;
    }
  }
  .filter-dialog-reset {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: space-between;
  }
}
</style>
