<template>
  <div class="table-container">
    <el-table
      :data="tableData"
      style="width: 100%"
      fit
      height="250"
      ref="multipleTable"
      @selection-change="selectChangeHandler"
      @select-all="selfChoose"
      @select="singleSelect"
      @row-click="rowClick"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column
        prop="className"
        label="场景"
        width="170"
        sortable
        align="center"
      >
        <template slot-scope="scope">
          <div class="className">
            <span>{{ scope.row.name }}</span>
            <!--            <span-->
            <!--              class="point"-->
            <!--              :style="{ background: pointColor(scope.row.activeStatus) }"-->
            <!--            ></span>-->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态" width="120" sortable>
        <template slot-scope="scope">
          <div class="switch-block className">
            <span v-if="scope.row.activeStatus === 1">已开机</span>
            <span v-else-if="scope.row.activeStatus === 0">已关机</span>
            <span v-else>已离线</span>
            <span
              class="point"
              :style="{ background: pointColor(scope.row.activeStatus) }"
            ></span>
            <!--            <el-switch-->
            <!--              :value="true"-->
            <!--              disabled-->
            <!--              active-color="#13ce66"-->
            <!--            ></el-switch>-->
          </div>
          <!--          <div v-else class="switch-block">-->
          <!--            <span>已关机</span>-->
          <!--            <el-switch-->
          <!--              :value="false"-->
          <!--              disabled-->
          <!--              active-color="#13ce66"-->
          <!--            ></el-switch>-->
          <!--          </div>-->
        </template>
      </el-table-column>
      <el-table-column prop="windSpeed" label="风速" width="50">
        <template slot-scope="scope">
          <!--          <span v-if="!scope.row.activeStatus">&#45;&#45;</span>-->
          <span>{{ scope.row.windOut || 0 }}档</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="first"
        label="初效滤网"
        :render-header="renderTitle"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ parseInt(scope.row.primaryFilter / 3600) }}小时</span>
          <span
            class="reset-bg"
            v-if="
              parseInt(scope.row.primaryFilter / scope.row.primaryFilterSum) <=
              0.05
            "
            >需更换</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="middle"
        label="中效滤网"
        :render-header="renderTitle"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ parseInt(scope.row.mediumFilter / 3600) }}小时</span>
          <span
            class="reset-bg"
            v-if="
              parseInt(scope.row.mediumFilter / scope.row.mediumFilterSum) <=
              0.05
            "
            >需更换</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="top"
        label="高效滤网"
        :render-header="renderTitle"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{ parseInt(scope.row.highFilter / 3600) }}小时</span>
          <span
            class="reset-bg"
            v-if="
              parseInt(scope.row.highFilter / scope.row.highFilterSum) <= 0.05
            "
            >需更换</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="pm25" label="PM2.5" width="70">
        <template slot-scope="scope">
          <!--          <span v-if="!scope.row.activeStatus">&#45;&#45;</span>-->
          <span>{{ scope.row.pm25 || 0 }}μg</span>
        </template>
      </el-table-column>
      <el-table-column prop="co2" label="二氧化碳" width="80">
        <template slot-scope="scope">
          <!--          <span v-if="!scope.row.activeStatus">&#45;&#45;</span>-->
          <span>{{ scope.row.co2 || 0 }}ppm</span>
        </template>
      </el-table-column>
      <el-table-column prop="temperature" label="温度" width="70">
        <template slot-scope="scope">
          <!--          <span v-if="!scope.row.activeStatus">&#45;&#45;</span>-->
          <span>{{ scope.row.temperature || 0 }}℃</span>
        </template>
      </el-table-column>
      <el-table-column prop="humidity" label="湿度" width="70">
        <template slot-scope="scope">
          <!--          <span v-if="!scope.row.activeStatus">&#45;&#45;</span>-->
          <span>{{ scope.row.humidity || 0 }}%</span>
        </template>
      </el-table-column>
      <!--      <el-table-column prop="jiaquan" label="甲醛" width="60"></el-table-column>-->
      <el-table-column label="空气质量" width="80">
        <template slot-scope="scope">
          <!--          <span v-if="!scope.row.activeStatus">&#45;&#45;</span>-->
          <span>{{ airText(scope.row.pm25) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <div class="options">
            <el-button type="text" @click.stop="setOptions(scope.row)"
              >设置</el-button
            >
            <span class="line">|</span>
            <span @click.stop="showChartDialog(scope.row)">小时图表</span>
            <span class="line">|</span>
            <el-button type="text" @click.stop="toDetail(scope.row.deviceId)"
              >详情</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="menu">
      <el-checkbox @change="extraChoose" v-model="extraBox">全选</el-checkbox>
      <span class="numCount"
        >已选中<span style="color: #1594fd" class="selectedNum">{{
          selectedNum
        }}</span
        >条</span
      >
      <div class="multiOption" @click="setAllOptions">批量设置</div>
      <div class="reset" @click="reset">重置</div>
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="listSum"
        :current-page="currentPage"
        :page-size="10"
        small
      >
      </el-pagination>
      <width-dialog
        :property="dialogProperty"
        @cancel="dialogCancel"
        @confirm="dialogConfirm"
        width="260px"
      >
        <div class="dialog-content" v-loading="loadingDeviceState">
          <div class="dialog-radio-power">
            <el-radio v-model="setNewDevice.power" :label="1">开机</el-radio>
            <el-radio v-model="setNewDevice.power" :label="0">关机</el-radio>
          </div>
          <div class="dialog-select-mode">
            <span>模式：</span>
            <el-select
              v-model="setNewDevice.mode"
              placeholder="请选择"
              size="mini"
              :disabled="setNewDevice.power === 0"
            >
              <el-option
                v-for="item in deviceModel === 2 ? modeOptions : modeOptions2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-select-wind">
            <span>风速：</span>
            <el-select
              v-model="setNewDevice.wind"
              placeholder="请选择"
              size="mini"
              :disabled="setNewDevice.mode !== 3 || setNewDevice.power === 0"
            >
              <el-option
                v-for="item in deviceModel === 2 ? windOptions : windOptions2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="dialog-select-timing">
            <span>定时：</span>
            <el-switch
              v-model="setNewDevice.timing"
              active-color="#13ce66"
              inactive-color="#999"
              :disabled="setNewDevice.power === 0"
            >
            </el-switch>
            <span style="font-size: 12px; color: #999">{{
              setNewDevice.timing ? "开启" : "关闭"
            }}</span>
          </div>
        </div>
      </width-dialog>
      <chart-dialog
        :chartProperty="chartDialogProperty"
        :chartDialogShow="chartDialogShow"
        :chart-data="chartDialogData"
        :footer="chartDialogFooter"
        @closeChartDialog="closeChartDialog"
        @changeTitle="chartDialogChangeTitle"
      >
      </chart-dialog>
    </div>
  </div>
</template>

<script>
import WidthDialog from "../dialog/WidthDialog";
import ChartDialog from "../dialog/ChartDialog";
import { dateFormat } from "../../utils/week";
export default {
  name: "DeviceTable",
  props: {
    tableData: Array,
    listSum: Number,
  },
  components: {
    WidthDialog,
    ChartDialog,
  },
  data() {
    return {
      chartDialogFooter: "",
      chartDialogData: {
        xArray: [],
        yArray: [],
      },
      currentPage: 1,
      chartDialogShow: false,
      extraBox: false,
      selectedNum: 0,
      dialogProperty: {
        show: false,
        title: "新风设置",
      },
      chartDialogProperty: {
        show: false,
        loading: false,
      },
      nowSetting: "",
      setNewDevice: {
        power: false,
        mode: 1,
        wind: 1,
        timing: false,
      },
      nowDevice: {
        power: false,
        mode: 1,
        wind: 1,
        timing: false,
      },
      modeOptions: [
        {
          label: "自动",
          value: 1,
        },
        {
          label: "睡眠",
          value: 4,
        },
        {
          label: "手动",
          value: 3,
        },
      ],
      modeOptions2: [
        {
          label: "睡眠",
          value: 4,
        },
        {
          label: "手动",
          value: 3,
        },
      ],
      windOptions: [
        {
          label: "1挡",
          value: 1,
        },
        {
          label: "2挡",
          value: 2,
        },
        {
          label: "3挡",
          value: 3,
        },
      ],
      windOptions2: [
        {
          label: "1挡",
          value: 1,
        },
        {
          label: "2挡",
          value: 2,
        },
        {
          label: "3挡",
          value: 3,
        },
        {
          label: "4挡",
          value: 4,
        },
        {
          label: "5挡",
          value: 5,
        },
        {
          label: "6挡",
          value: 6,
        },
        {
          label: "7挡",
          value: 7,
        },
        {
          label: "8挡",
          value: 8,
        },
        {
          label: "9挡",
          value: 9,
        },
        {
          label: "10挡",
          value: 10,
        },
      ],
      loadingDeviceState: false,
      currentId: "",
      DeviceId: "",
      selectionItem: [],
      deviceModel: 0,
    };
  },
  created() {},
  methods: {
    airText(num) {
      if (num < 50) {
        return "空气清新";
      } else if (num <= 100) {
        return "空气良好";
      } else if (num <= 150) {
        return "轻度污染";
      } else if (num <= 200) {
        return "中度污染";
      } else if (num <= 300) {
        return "重度污染";
      } else {
        return "严重污染";
      }
    },
    pointColor(state) {
      if (state === 0) {
        return "#a6a6a6";
      } else if (state === 1) {
        return "#58c328";
      } else {
        return "#ff6c6c";
      }
    },
    renderTitle(h, { column }) {
      return h("div", {
        attrs: {
          class: "cell-title", //
        },
        domProps: {
          innerHTML: column.label + '<span class="rest-time">(剩余时间)</span>',
        },
      });
    },
    extraChoose(e) {
      if (e) {
        this.$refs.multipleTable.toggleAllSelection();
      } else {
        this.$refs.multipleTable.clearSelection();
        this.selectedNum = 0;
      }
    },
    selfChoose(list) {
      this.extraBox = list.length > 0;
      this.selectedNum = list.length;
    },
    singleSelect(list) {
      this.selectedNum = list.length;
    },
    toDetail(id) {
      const { href } = this.$router.resolve({
        path: `/charts/${id}`,
      });
      window.open(href, "_blank");
    },

    //设置单个设备
    setOptions(device) {
      1;
      console.log(device.deviceId);
      this.dialogProperty.show = true;
      this.dialogProperty.title = "设置";
      this.loadingDeviceState = true;
      this.deviceId = device.deviceId;
      this.$http
        .getSingleDeviceStatus(device.deviceId)
        .then((res) => {
          console.log(res, "mode check");
          this.nowDevice.power = res.data.activeStatus;
          this.nowDevice.mode = res.data.workPattern || 1;
          this.nowDevice.wind = res.data.windOut;
          // this.nowDevice.wind = res.data.windOut === 0 ? 1 : res.data.windOut;
          this.nowDevice.timing = res.data.timingStatus;
          this.deviceModel = res.data.deviceModel;
          // this.nowDevice.power = data2 === 1;
          if (this.nowDevice.wind === 0) {
            this.nowDevice.wind = 1;
          }
          this.setNewDevice = { ...this.nowDevice };
          this.loadingDeviceState = false;
        })
        .catch(() => {
          this.loadingDeviceState = false;
        });
    },
    //重置
    reset() {
      this.$refs.multipleTable.clearSelection();
      this.extraBox = false;
    },
    //checkbox改变
    selectChangeHandler(selection) {
      this.selectionItem = selection;
    },

    setAllOptions() {
      if (!this.selectionItem.length) return;
      this.dialogProperty.show = true;
      this.dialogProperty.title = "批量设置";
      this.setNewDevice = this.nowDevice = {
        power: false,
        mode: 1,
        wind: 1,
        timing: false,
      };
    },

    dialogCancel() {
      this.dialogProperty.show = false;
    },
    closeChartDialog() {
      this.chartDialogShow = false;
    },
    chartDialogChangeTitle(title) {
      this.chartDialogProperty.loading = true;
      let filed;
      if (title === "PM2.5") {
        filed = "PM25";
      } else if (title === "CO2") {
        filed = "CO2";
      } else if (title === "温度") {
        filed = "wendu";
      } else if (title === "湿度") {
        filed = "shidu";
      } else {
        filed = "TVOC";
      }
      this.$http
        .getHourChart(this.currentId, 0, 1, filed)
        .then((res) => {
          this.chartDialogData.xArray = res.data.xAxisV.map((item) => {
            return item.slice(11, 16);
          });
          this.chartDialogData.yArray = res.data.yAxisV;
          // this.chartDialogFooter = dateFormat(new Date(), "yyyy-MM-dd");
          this.chartDialogProperty.loading = false;
        })
        .catch(() => {
          this.chartDialogProperty.loading = false;
        });
    },
    //单个设备请求方法
    singleDeviceSet(deviceId) {
      let data =
        "" +
        this.setNewDevice.power +
        this.setNewDevice.wind +
        this.setNewDevice.mode;
      console.log(data);
      this.$http
        .getDeviceState(deviceId, "setPower", this.setNewDevice.power === 1)
        .then((res) => {
          console.log(res, "power");

          if (res.data === this.setNewDevice.power && res.data !== 0) {
            const params = {
              deviceId: deviceId,
              ts: new Date().getTime(),
            };
            this.$http.PowerOnDevice(params).then((res) => {
              console.log(res, "提交到数据库");
            });
            this.$http
              .getDeviceState(deviceId, "setMode", this.setNewDevice.mode)
              .then((res) => {
                if (res.data === this.setNewDevice.mode && res.data === 3) {
                  this.$http
                    .getDeviceState(
                      deviceId,
                      "setSpeed",
                      this.setNewDevice.wind
                    )
                    .then((res) => {
                      if (res.data === this.setNewDevice.wind) {
                        this.$http
                          .setDeviceTiming(deviceId, this.setNewDevice.timing)
                          .then(() => {
                            this.$message({
                              type: "success",
                              message: "操作成功",
                            });
                            this.$emit("refreshTable", deviceId, data);
                          });
                      }
                    });
                } else {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                  });
                  this.$emit("refreshTable", deviceId, data);
                }
              });
          } else if (res.data === this.setNewDevice.power && res.data === 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.$emit("refreshTable", deviceId, data);
          } else if (res.data !== this.setNewDevice.power) {
            this.$message({
              type: "error",
              message: "操作失败",
            });
          }
        });
      this.dialogProperty.show = false;
    },
    dialogConfirm() {
      if (this.loadingDeviceState) return;
      if (this.dialogProperty.title === "批量设置") {
        this.selectionItem.forEach((item) => {
          this.singleDeviceSet(item.deviceId);
        });
      } else {
        this.singleDeviceSet(this.deviceId);
      }
    },

    //显示图表弹窗
    showChartDialog(device) {
      // console.log(device.deviceId);
      this.currentId = device.deviceId;
      this.chartDialogShow = true;
      this.chartDialogProperty.loading = true;
      this.chartDialogFooter = dateFormat("YYYY-mm-dd", new Date());
      this.$http
        .getHourChart(device.deviceId, 0, "1", "PM25")
        .then((res) => {
          console.log(res, "小时图表");
          this.chartDialogData.xArray = res.data.xAxisV.map((item) => {
            return item.slice(11, 16);
          });
          this.chartDialogData.yArray = res.data.yAxisV;
          this.chartDialogProperty.loading = false;
        })
        .catch(() => {
          this.chartDialogProperty.loading = false;
        });
    },
    //行点击
    rowClick(row) {
      const { href } = this.$router.resolve({
        path: `/charts/${row.deviceId}`,
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  ::v-deep.el-table {
    //height: 70%;
    //overflow-y: scroll;
    &::before {
      bottom: 100000px;
    }
    .el-table-column--selection {
      .cell {
        padding-left: 10px;
      }
    }
    .cell-title {
      display: flex;
      justify-content: center;
      color: #333;
      line-height: 18px;
      flex-direction: column;
      .rest-time {
        font-size: 12px;
        color: #999;
      }
    }
    th > .cell {
      text-align: center;
      color: #333;
    }
    .el-table__row .cell {
      text-align: center;
      //"需更换"样式
      .reset-bg {
        line-height: 18px;
        display: inline-block;
        font-size: 12px;
        width: 42px;
        height: 18px;
        background-color: #ff6c6c;
        border-radius: 10px;
        color: #fff;
        margin-left: 5px;
      }
    }
    .has-gutter > tr {
      th {
        background-color: #eaf8f8 !important;
      }
    }
    //表头高度
    .el-table__header tr,
    .el-table__header th {
      padding: 0;
      height: 50px;
    }
    .className {
      display: flex;
      align-items: center;
      justify-content: center;
      .point {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-left: 5px;
      }
    }
    .switch-block {
      display: flex;
      align-items: center;
      span {
        margin-right: 5px;
      }
    }
    .options {
      display: flex;
      align-items: center;
      .line {
        margin-left: 5px;
        margin-right: 5px;
      }
      > span:nth-child(3) {
        cursor: pointer;
      }
    }
  }
  .menu {
    display: flex;
    align-items: center;
    height: 50px;
    border-top: 1px solid #ebeef5;
    font-size: 14px;
    .el-checkbox {
      margin-left: 10px;
    }
    .numCount {
      margin-left: 8px;
      color: #606266;
      letter-spacing: 1px;
      .selectedNum {
        display: inline-block;
        min-width: 20px;
        text-align: center;
      }
    }
    .multiOption {
      width: 66px;
      height: 26px;
      border-radius: 5px;
      background-color: #4c8bfd;
      color: #fff;
      line-height: 26px;
      text-align: center;
      cursor: pointer;
      font-size: 12px;
      margin-left: 10px;
    }
    .reset {
      width: 66px;
      height: 26px;
      border-radius: 5px;
      color: #666;
      line-height: 26px;
      text-align: center;
      cursor: pointer;
      font-size: 12px;
      border: solid 1px #dfdfdf;
      margin-left: 10px;
    }
    .dialog-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .dialog-radio-power {
        width: 56%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-radio {
          margin-right: 15px;
        }
      }
      .dialog-select-mode,
      .dialog-select-wind,
      .dialog-select-timing {
        display: flex;
        width: 56%;
        margin-top: 16px;
        align-items: center;
        justify-content: space-between;
        .el-select {
          width: 70px;
        }
      }
    }
    .el-pagination {
      flex: 1;
      text-align: end;
      margin-right: 20px;
    }
  }
}
</style>
