<template>
  <div class="echarts-container">
    <div :id="Echartsdata.id" class="wendu-echarts"></div>
    <date-block class="date-block" :active="active" cpn="wendu"></date-block>
  </div>
</template>
<script>
import { DateBlock } from "../layout";
export default {
  name: "wenduLineEcharts",
  components: {
    DateBlock,
  },
  props: {
    Echartsdata: Object,
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      myChart4: {},
    };
  },
  mounted() {
    //调用echarts
    // console.log(this.wenduLineEchartsdata);
    this.initStates();
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myChart4);
  },
  methods: {
    initStates() {
      this.myChart4 = this.$echarts.init(
        document.getElementById(this.Echartsdata.id)
      );
      // console.log(111);
      let option = {
        title: [
          {
            text: this.Echartsdata.name,
            left: "15px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            text: this.Echartsdata.nowValue + "℃",
            right: "25px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ["最高气温", "最低气温"],
        // },
        grid: {
          top: "30%",
          right: "10%",
          left: "10%",
          bottom: "20%",
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.Echartsdata.x,
        },
        yAxis: {
          type: "value",
          //   axisLabel: {
          //     formatter: "{value} °C",
          //   },
        },
        series: [
          {
            name: "温度",
            type: "line",
            data: this.Echartsdata.y,
            color: "#1594fd",
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            // markLine: {
            //   data: [{ type: "average", name: "平均值" }],
            // },
          },
          {
            name: "最低气温",
            type: "line",
            data: this.Echartsdata.yd,
            markPoint: {
              data: [{ type: "min", name: "最小值" }],
            },
            // markLine: {
            //   data: [
            //     { type: "average", name: "平均值" },
            //     [
            //       {
            //         symbol: "none",
            //         x: "90%",
            //         yAxis: "max",
            //       },
            //       {
            //         symbol: "circle",
            //         label: {
            //           position: "start",
            //           formatter: "最大值",
            //         },
            //         type: "max",
            //         name: "最高点",
            //       },
            //     ],
            //   ],
            // },
          },
        ],
      };
      this.myChart4.setOption(option);
    },
    // setHumidity(index) {
    //   if (index == 1) {
    //     this.humidityValue = 40;
    //     this.myChart.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   } else if (index == 2) {
    //     this.humidityValue = 70;
    //     this.myChart2.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   }
    // },
    // updateHumidity() {},
  },
  watch: {
    Echartsdata: {
      handler() {
        this.initStates();
        console.log(this.Echartsdata, "echarts");
      },
      deep: true,

      // this.option.series[0].data = this.Echartsdata.y;
      // this.myChart5.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
.echarts-container {
  position: relative;
}
.wendu-echarts {
  // width: auto;
  //width: 300px;
  width: 100%;
  height: 100%;
  //height: 350px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  background-color: #ffffff;
  text-align: center;
  padding-bottom: 10px;
  position: relative;
}
.date-block {
  position: absolute;
  bottom: 1px;
  left: 30%;
}
</style>
