<template>
  <div id="States"></div>
</template>

<script>
export default {
  name: "FreshAirStates",
  // props: {
  //   statesData: Array,
  // },
  data() {
    return {
      myChart: {},
      statesData: [
        {
          name: "已开机",
          value: 0,
        },
        {
          name: "已关机",
          value: 0,
        },
        {
          name: "已离线",
          value: 0,
        },
      ],
    };
  },
  mounted() {
    //调用echarts
    this.$http
      .getAirState(window.localStorage.getItem("assetID"))
      .then((res) => {
        console.log(res, "airstate");
        this.statesData[0].value = res?.data.activeNumber || 0;
        this.statesData[1].value = res?.data.shutdownNumber || 0;
        this.statesData[2].value = res?.data.offLineNumber || 0;
        this.initStates();
      });
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myChart);
  },

  methods: {
    initStates() {
      const that = this;
      this.myChart = this.$echarts.init(document.getElementById("States"));
      // console.log(111);
      let option = {
        title: [
          {
            text: "新风机状态统计",
            top: "20px",
            textStyle: {
              fontWeight: "normal",
              fontSize: 15,
            },
            left: "10px",
          },
          {
            text: `总数：${
              that.statesData[0].value +
              that.statesData[1].value +
              that.statesData[2].value
            }台`,
            textStyle: {
              fontWeight: "normal",
              fontSize: 15,
            },
            top: "20px",
            right: "10px",
          },
          {
            text: `${
              that.statesData[0].value +
              that.statesData[1].value +
              that.statesData[2].value
            }台`,
            top: 160,
            left: "center",

            //transform: translate("-50%", "-50%"),
            textStyle: {
              fontWeight: "normal",
              fontSize: 15,
            },
            z: -1,
          },
        ],
        // grid: {
        //   top: "20%",
        //   bottom: "10%", //也可设置left和right设置距离来控制图表的大小
        //   left: "30%",
        //   right: "30%",
        // },
        tooltip: {
          trigger: "item",
        },
        height: 350,
        legend: {
          top: "77%",
          left: "center",
          //formatter: `{name}{value}`,
          // orient: "horizontal",
          // height: "10px",
          width: "100px",
          formatter: function (params) {
            if (params === "已开机") {
              return params + that.statesData[0].value + "台";
            } else if (params === "已关机") {
              return params + that.statesData[1].value + "台";
            } else if (params === "已离线") {
              return params + that.statesData[2].value + "台";
            }
            //return params.name + params.value;
          },
        },
        color: ["#01cfa5", "#a6a6a6", "#ff6c6c"],
        series: [
          {
            name: "新风机状态",
            type: "pie",
            radius: ["30%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              formatter: (label) => {
                let total =
                  that.statesData[0].value +
                  that.statesData[1].value +
                  that.statesData[2].value;
                if (total === 0) total = 1;
                return (
                  label.name +
                  "\n" +
                  ((label.value * 100) / total).toFixed(2) +
                  "%" +
                  "\n" +
                  label.value +
                  "台"
                );
              },
              color: "#999",
              lineHeight: 18,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                backgroundColor: "#ffffff",
                //borderWidth: "40px",
                //width: "15px",
                formatter: function (label) {
                  let total =
                    that.statesData[0].value +
                    that.statesData[1].value +
                    that.statesData[2].value;
                  if (total === 0) total = 1;
                  return (
                    label.name +
                    "\n" +
                    ((label.value * 100) / total).toFixed(2) +
                    "%" +
                    "\n" +
                    label.value +
                    "台"
                  );
                  // return " " + params.value + "台 ";
                },
              },
            },
            labelLine: {
              show: true,
              length: 20,
            },
            data: this.statesData,
          },
        ],
      };
      this.myChart.setOption(option);
      // window.onresize = function () {
      //   this.myChart.resize();
      // };
    },
    // setHumidity(index) {
    //   if (index == 1) {
    //     this.humidityValue = 40;
    //     this.myChart.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   } else if (index == 2) {
    //     this.humidityValue = 70;
    //     this.myChart2.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   }
    // },
    // updateHumidity() {},
  },
  watch: {
    Echartsdata() {
      this.initStates();
      // this.option.series[0].data = this.Echartsdata.y;
      // this.myChart5.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
#States {
  // width: auto;
  //width: 300px;

  //height: 350px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  text-align: center;

  position: relative;
}
</style>
