<template>
  <div class="echarts-container">
    <div :id="Echartsdata.id" class="jiaquan-echarts"></div>
    <date-block class="date-block" :active="active" cpn="jiaquan"></date-block>
  </div>
</template>

<script>
import { DateBlock } from "../layout";
export default {
  name: "jiaquanEcharts",
  components: {
    DateBlock,
  },
  props: {
    Echartsdata: Object,
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      myChart5: {},
      option: {},
    };
  },
  mounted() {
    //调用echart
    // console.log(this.Echartsdata);
    this.initStates();
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myChart5);
  },
  methods: {
    jiaquanchange(a) {
      if (a.nowValue <= 80) {
        return `{a| 优 }`;
      }
      if (a.nowValue > 80 && a.nowValue <= 100) {
        return `{b| 良 }`;
      }
      if (a.nowValue > 110 && a.nowValue <= 300) {
        return `{c| 轻度 }`;
      }
      if (a.nowValue > 300 && a.nowValue <= 600) {
        return `{d| 中度 }`;
      }
      if (a.nowValue > 600) {
        return `{e| 重度 }`;
      }
    },
    initStates() {
      this.myChart5 = this.$echarts.init(
        document.getElementById(this.Echartsdata.id)
      );
      // console.log(111);
      this.option = {
        title: [
          {
            text: this.Echartsdata.name,
            left: "15px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            text: this.Echartsdata.nowValue + "μg/m³",
            right: "55px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            subtext: this.jiaquanchange(this.Echartsdata),

            right: "16px",
            top: "4.5px",
            height: "20px",
            subtextStyle: {
              color: "#ffffff",
              fontSize: "13px",
              right: "20px",
              top: "17px",
              //height: "20px",
              lineOverflow: "none",
              rich: {
                a: {
                  backgroundColor: "#86c82a",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 25,
                  //verticalAlign: "middle",
                  align: "center",
                },
                b: {
                  backgroundColor: "#43bf58",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 25,
                  //verticalAlign: "middle",
                  align: "center",
                },
                c: {
                  backgroundColor: "#ffb246",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                d: {
                  backgroundColor: "#fe6718",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                e: {
                  backgroundColor: "#dd3330",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                f: {
                  backgroundColor: "#bd034e",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "30px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.Echartsdata.x,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: this.Echartsdata.name,
            type: "bar",
            barWidth: "60%",
            color: "#01cfa5",
            data: this.Echartsdata.y,
          },
        ],
      };
      this.myChart5.setOption(this.option);
    },
    // setHumidity(index) {
    //   if (index == 1) {
    //     this.humidityValue = 40;
    //     this.myChart.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   } else if (index == 2) {
    //     this.humidityValue = 70;
    //     this.myChart2.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   }
    // },
    // updateHumidity() {},
  },
  watch: {
    Echartsdata: {
      handler() {
        this.initStates();
        console.log(this.Echartsdata, "echarts");
      },
      deep: true,

      // this.option.series[0].data = this.Echartsdata.y;
      // this.myChart5.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
.echarts-container {
  position: relative;
}
.jiaquan-echarts {
  // width: auto;
  //width: 300px;
  width: 100%;
  height: 100%;
  //height: 350px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  background-color: #ffffff;
  text-align: center;
  padding-bottom: 10px;
  position: relative;
}
.date-block {
  position: absolute;
  bottom: 1px;
  left: 30%;
}
</style>
