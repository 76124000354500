<template>
  <div class="alarm1">
    <div class="header">
      <div class="ll"></div>
      <div class="left">告警信息</div>
      <div class="right" @click="toAlarm">更多</div>
    </div>
    <div class="main">
      <template v-for="item in alarmList">
        <div class="li" :key="item">
          <div class="ll"></div>
          <div class="ll"></div>
          <div class="lo"></div>
          <div class="ll"></div>
          <div class="text">{{ item }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alarm1",
  props: {
    alarmList: Array,
  },
  methods: {
    toAlarm() {
      const { href } = this.$router.resolve({
        path: `alarm`,
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.alarm1 {
  // width: auto;
  //width: 319px;
  width: 100%;
  height: 100%;
  //height: 172px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  background-color: #ffffff;
  /*实现水平居中*/
  justify-content: center;

  position: relative;
  color: #333;
  .header {
    display: flex;
    width: 319px;
    height: 50px;
    .ll {
      display: flex;
      width: 20px;
    }
    .left {
      display: flex;
      color: #333;
      width: 250px;
      line-height: 20px;
      font-size: 15px;
      //   /*实现水平居中*/
      //   justify-content: center;
      /*实现垂直居中*/
      align-items: center;
    }
    .right {
      display: flex;
      width: 40px;
      left: 270px;
      font-size: 12px;
      /*实现水平居中*/
      justify-content: center;
      /*实现垂直居中*/
      align-items: center;
      margin-right: 25px;
      cursor: pointer;
    }
    .right:hover {
      color: #4c8bfd;
    }
  }
  .main {
    display: flex;
    height: 100px;
    width: 300px;
    left: 10px;
    flex-direction: column;
    .li {
      display: flex;
      width: 300px;
      height: 30px;
      /*实现垂直居中*/
      align-items: center;
      .ll {
        display: flex;
        width: 20px;
      }

      .lo {
        position: relative;
        width: 23px;
        height: 20px;
        background: url("../../../public/bj.png");
        -webkit-background-size: 19px 19px;
      }
      .text {
        width: 300px;
        height: 10px;
        font-size: 12px;
        /*实现垂直居中*/
        align-items: center;
      }
    }
  }
}
</style>
