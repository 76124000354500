<template>
  <div class="echarts-container">
    <div :id="Echartsdata.id" class="shidu-echarts"></div>
    <date-block class="date-block" :active="active" cpn="shidu"></date-block>
  </div>
</template>

<script>
import { DateBlock } from "../layout";
export default {
  name: "shiduEcharts",
  components: {
    DateBlock,
  },
  props: {
    Echartsdata: Object,
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      myChart4: {},
      option: {},
    };
  },
  mounted() {
    //调用echart
    console.log(this.shiduEchartsdata);
    this.initStates();
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myChart4);
  },
  methods: {
    initStates() {
      this.myChart4 = this.$echarts.init(
        document.getElementById(this.Echartsdata.id)
      );
      // console.log(111);
      this.option = {
        title: [
          {
            text: this.Echartsdata.name,
            left: "15px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            text: this.Echartsdata.nowValue + "%",
            right: "25px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
        ],
        //backgroundColor: '#0f375f',
        grid: {
          top: "25%",
          bottom: "20%", //也可设置left和right设置距离来控制图表的大小
        },
        tooltip: {
          trigger: "item",
          // axisPointer: {
          type: "line",
          label: {
            show: true,
          },
          // },
        },

        xAxis: {
          data: this.Echartsdata.x,
          axisLine: {
            show: true, //隐藏X轴轴线
            lineStyle: {
              color: "#8a8a8a",
            },
          },
          axisTick: {
            show: true, //隐藏X轴刻度
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#8a8a8a", //X轴文字颜色
            },
          },
        },
        yAxis: [
          {
            type: "value",
            //name: "亿元",
            nameTextStyle: {
              color: "#ebf8ac",
            },
            splitLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#8a8a8a",
              },
            },
            // axisLabel: {
            //     show: true,
            //     textStyle: {
            //         color: "#ebf8ac"
            //     }
            // },
          },
        ],
        series: [
          {
            name: this.Echartsdata.name,
            type: "line",
            //yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
            //smooth: true, //平滑曲线显示
            //showAllSymbol: true, //显示所有图形。
            symbol: "circle", //标记的图形为实心圆
            symbolSize: 0, //标记的大小
            itemStyle: {
              //折线拐点标志的样式
              color: "#39debf",
            },

            normal: {
              color: "#39debf", //改变折线点的颜色
              lineStyle: {
                color: "#39debf", //改变折线颜色
              },
            },
            z: 1,
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#39debf",
                  },
                  {
                    offset: 1,
                    color: "#ffffff",
                  },
                ]),

                //shadowColor: "rgba(0,202,149, 0.9)",
                //shadowBlur: 20,
              },
            },
            data: this.Echartsdata.y,
          },
          {
            name: this.Echartsdata.name,
            type: "bar",
            barWidth: 15,
            itemStyle: {
              normal: {
                color: "#39debf",
              },
            },
            data: this.Echartsdata.y,
          },
        ],
      };
      this.myChart4.setOption(this.option);
    },
    // setHumidity(index) {
    //   if (index == 1) {
    //     this.humidityValue = 40;
    //     this.myChart.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   } else if (index == 2) {
    //     this.humidityValue = 70;
    //     this.myChart2.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   }
    // },
    // updateHumidity() {},
  },
  watch: {
    Echartsdata: {
      handler() {
        this.initStates();
        console.log(this.Echartsdata, "echarts");
      },
      deep: true,

      // this.option.series[0].data = this.Echartsdata.y;
      // this.myChart5.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
.echarts-container {
  position: relative;
}
.shidu-echarts {
  // width: auto;
  //width: 300px;
  width: 100%;
  height: 100%;
  //height: 350px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  background-color: #ffffff;
  text-align: center;
  padding-bottom: 10px;
  position: relative;
}
.date-block {
  position: absolute;
  bottom: 1px;
  left: 30%;
}
</style>
