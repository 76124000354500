<template>
  <div class="width-dialog">
    <el-dialog
      :title="property.title"
      :visible.sync="property.show"
      :width="width"
      top="20vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <slot></slot>
      <span slot="footer" class="dialog-footer" v-loading="property.loading">
        <el-button @click="cancel" size="mini" class="cancelBtn"
          >取 消</el-button
        >
        <el-button @click="confirm" size="mini" class="confirmBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "WidthDialog",
  props: {
    property: Object,
    width: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm", 2);
    },
  },
};
</script>

<style scoped lang="scss">
.width-dialog {
  ::v-deep.el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      border-bottom: 1px solid #eaeaea;
    }
    .cancelBtn,
    .confirmBtn {
      width: 80px;
      // &:hover {
      //   background: #00d4a1;
      //   color: #fff;
      // }
      &:active {
        border-color: #00d4a1;
        color: #606266;
      }
      &:focus {
        background: #fff;
        color: #606266;
        border-color: #dcdfe6;
      }
    }
    .confirmBtn {
      background: #00d4a1;
      color: #fff;
      &:hover {
        background: #4ce4c0;
        color: #fff;
      }
    }
    .cancelBtn {
      &:hover {
        border-color: #c3e9e1;
        background: #eafffb;
        color: #01cfa5;
      }
    }
    .el-dialog__footer {
      text-align: center;
    }
  }
}
</style>
