<template>
  <div
    class="small-block-container"
    :style="[
      { background: bgColorComp(item.title) },
      borderStyle && { border: '1px solid' + leftBgColorComp(item.title) },
    ]"
  >
    <div
      class="icon-wrapper"
      :style="{ background: leftBgColorComp(item.title) }"
    >
      <img :src="iconUrl(item.title)" alt="" />
    </div>
    <div class="content-wrapper">
      <div class="content-info">
        <div class="content-desc">
          <span>{{ item.title }}</span>
          <span v-if="item.title === '新风系统'">待维护</span>
          <span v-else>待更换</span>
        </div>
        <span class="content-num">{{ item.num }}</span>
      </div>
      <div v-if="item.num && item.extra" class="content-extra">
        {{ item.extra }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallBlock",
  props: {
    item: Object,
    bgColor: {
      default: true,
      type: Boolean,
    },
    borderStyle: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    bgColorComp(title) {
      if (!this.bgColor) return "";
      if (title === "新风系统") {
        return "#ffeeee";
      } else if (title === "初效滤网") {
        return "#e9f5ff";
      } else if (title === "中效滤网") {
        return "#ebfffd";
      } else if (title === "高效滤网") {
        return "#f1ffea";
      } else {
        return "";
      }
    },
    leftBgColorComp(title) {
      if (title === "新风系统") {
        return "#ff6c6c";
      } else if (title === "初效滤网") {
        return "#1594fd";
      } else if (title === "中效滤网") {
        return "#01c2b3";
      } else if (title === "高效滤网") {
        return "#58c328";
      } else {
        return "";
      }
    },
    iconUrl(title) {
      if (title === "新风系统") {
        return require("../../assets/images/a01.png");
      } else if (title === "初效滤网") {
        return require("../../assets/images/a02.png");
      } else if (title === "中效滤网") {
        return require("../../assets/images/a03.png");
      } else if (title === "高效滤网") {
        return require("../../assets/images/a04.png");
      } else {
        return "";
      }
    },
    toDetail(title) {
      if (title === "新风系统") {
        this.$router.push({
          path: "detail/maintain",
        });
      } else {
        this.$router.push({
          path: "detail/filter",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.small-block-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .icon-wrapper {
    width: 32%;
    //height: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      padding-bottom: 100%;
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    .content-info {
      display: flex;
      align-items: center;
      color: #333333;
      .content-desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
          &:nth-child(1) {
            font-size: 20px;
          }
          &:nth-child(2) {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
      .content-num {
        min-width: 50px;
        font-size: 52px;
        margin-left: 25px;
      }
    }
    .content-extra {
      color: #666666;
      font-size: 12px;
      max-width: 130px;
      overflow: hidden;
    }
  }
}
</style>
