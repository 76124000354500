<template>
  <div id="Online"></div>
</template>

<script>
import { getBeforeDate } from "../../utils/week";

export default {
  name: "Online",
  data() {
    return {
      myChart2: {},
      dataArray: [],
      yArray: [0, 0, 0, 0, 0, 0, 0],
    };
  },
  mounted() {
    //调用echarts
    this.dataArray = this.getDateArray().reverse();
    this.$http
      .getOnlineStatistics(window.localStorage.getItem("assetID"))
      .then((res) => {
        this.dataArray.forEach((item, index) => {
          this.yArray[index] =
            res.data[new Date().getFullYear() + "-" + item] || 0;
        });
        this.initStates();
      });
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myChart2);
  },
  methods: {
    initStates() {
      this.myChart2 = this.$echarts.init(document.getElementById("Online"));
      // console.log(222);
      let option = {
        title: {
          text: "一周在线统计",
          textStyle: {
            fontWeight: "normal",
            fontSize: 15,
          },
          top: "5%",
          left: "1.5%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "35%",
          right: "10%",
          left: "10%",
          bottom: "18%",
        },
        xAxis: [
          {
            type: "category",
            // data: ["13/27", "13/28", "13/29", "13/30", "13/31", "4/11", "4/12"],
            data: this.dataArray,
            axisLine: {
              lineStyle: {
                color: "#ffffff",
              },
            },
            axisLabel: {
              margin: 10,
              color: "#a6a6a6",
              // rotate: 35,
              textStyle: {
                fontSize: 10,
              },
            },
          },
        ],
        yAxis: [
          {
            show: false,
            //splitNumber: 3,
          },
        ],
        series: [
          {
            type: "bar",
            data: this.yArray,
            barWidth: "20px",
            barMinHeight: 25,
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.value === 0) {
                    return "#a6a6a6";
                  } else {
                    return "#1f8efa";
                  }
                },
                barBorderRadius: [30, 30, 30, 30],
                //shadowColor: "rgba(0,160,221,1)",
              },
            },
            label: {
              show: true,
              position: "top",
              padding: 5,
            },
          },
        ],
      };
      this.myChart2.setOption(option);
    },
    getDateArray() {
      const dateNums = [0, 1, 2, 3, 4, 5, 6];
      return dateNums.map((item) => {
        return getBeforeDate(item);
      });
    },
    // setHumidity(index) {
    //   if (index == 1) {
    //     this.humidityValue = 40;
    //     this.myChart.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   } else if (index == 2) {
    //     this.humidityValue = 70;
    //     this.myChart2.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   }
    // },
    // updateHumidity() {},
  },
};
</script>

<style scoped lang="scss">
#Online {
  // width: auto;
  width: 100%;
  /*   width:100%;
  height: 100%;*/
  height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  background-color: #ffffff;
  text-align: center;

  position: relative;
}
</style>
