<template>
  <div class="echarts-container">
    <div :id="Echartsdata.id" class="bar-echarts"></div>
    <date-block
      class="date-block"
      :active="active"
      :cpn="Echartsdata.name === 'PM2.5' ? 'PM25' : 'CO2'"
    ></date-block>
  </div>
</template>

<script>
import { DateBlock } from "../layout";

export default {
  name: "BarEcharts",
  components: {
    DateBlock,
  },
  props: {
    Echartsdata: Object,
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      myChart3: {},
      font: "",
    };
  },
  mounted() {
    //调用echarts
    // console.log(this.Echartsdata);

    this.initStates();
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myChart3);
  },
  methods: {
    AQIcolor(a) {
      console.log(a.name);
      console.log(a);
      if (a.name === "PM2.5") {
        console.log(a.nowValue, "nowV");
        if (a.nowValue <= 50) {
          return `{a| 优 }`;
        } else if (a.nowValue > 50 && a.nowValue <= 100) {
          return `{b| 良 }`;
        } else if (a.nowValue > 100 && a.nowValue <= 150) {
          return `{c| 轻度 }`;
        } else if (a.nowValue > 150 && a.nowValue <= 200) {
          return `{d| 中度 }`;
        } else if (a.nowValue > 200 && a.nowValue <= 300) {
          return `{e| 重度 }`;
        } else if (a.nowValue > 300) {
          return `{f| 严重 }`;
        }
      }
      if (a.name === "CO2") {
        console.log(a.nowValue, "nowVCO2");
        if (a.nowValue === 0) {
          return `{a| 优 }`;
        }
        if (a.nowValue > 350 && a.nowValue <= 450) {
          return `{a| 优 }`;
        }
        if (a.nowValue > 450 && a.nowValue <= 1000) {
          return `{b| 良 }`;
        }
        if (a.nowValue > 1000 && a.nowValue <= 2000) {
          return `{c| 轻度 }`;
        }
        if (a.nowValue > 2000 && a.nowValue <= 5000) {
          return `{d| 中度 }`;
        }
        if (a.nowValue > 5000) {
          return `{e| 重度 }`;
        }
      }
      // if(a.name=="CO2"){

      // }
    },
    initStates() {
      if (this.Echartsdata.name === "PM2.5") {
        this.font = "μg/m³";
      }
      if (this.Echartsdata.name === "CO2") {
        this.font = "ppm";
      }
      this.myChart3 = this.$echarts.init(
        document.getElementById(this.Echartsdata.id)
      );
      // console.log(111);
      let option = {
        title: [
          {
            text: this.Echartsdata.name,
            left: "15px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            text: this.Echartsdata.nowValue + this.font,
            right: "55px",
            top: "15px",
            textStyle: {
              fontWeight: "normal",
            },
          },
          {
            subtext: this.AQIcolor(this.Echartsdata),

            right: "16px",
            top: "4.5px",
            height: "20px",
            subtextStyle: {
              color: "#ffffff",
              fontSize: "13px",
              right: "20px",
              top: "17px",
              //height: "20px",
              lineOverflow: "none",
              rich: {
                a: {
                  backgroundColor: "#86c82a",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 25,
                  //verticalAlign: "middle",
                  align: "center",
                },
                b: {
                  backgroundColor: "#43bf58",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 25,
                  //verticalAlign: "middle",
                  align: "center",
                },
                c: {
                  backgroundColor: "#ffb246",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                d: {
                  backgroundColor: "#fe6718",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                e: {
                  backgroundColor: "#dd3330",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
                f: {
                  backgroundColor: "#bd034e",
                  fontSize: 13,
                  borderRadius: 3,
                  height: 17,
                  width: 35,
                  //verticalAlign: "middle",
                  align: "center",
                },
              },
            },
          },
        ],
        tooltip: {
          trigger: "axis",

          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "28%",
          right: "10%",
          left: "15%",
          bottom: "20%",
        },
        xAxis: [
          {
            type: "category",
            data: this.Echartsdata.x,
            axisLine: {
              lineStyle: {
                // color: "#a6a6a6",
              },
            },
            // axisLabel: {
            //   margin: 10,
            //   color: "#a6a6a6",
            //   textStyle: {
            //     fontSize: 10,
            //   },
            // },
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              lineStyle: {
                color: "#eee",
              },
            },
            splitNumber: 3,
          },
        ],
        series: [
          {
            type: "bar",
            data: this.Echartsdata.y,
            name: this.Echartsdata.name,
            barMaxWidth: "20px",
            itemStyle: {
              normal: {
                color: this.Echartsdata.color,
                barBorderRadius: [30, 30, 0, 0],
                //shadowColor: "rgba(0,160,221,1)",
              },
            },
            // label: {
            //   show: true,
            //   position: "top",
            //   padding: 0,
            // },
          },
        ],
      };
      this.myChart3.setOption(option);
      // window.onresize = function () {
      //   this.myChart3.resize();
      // };
    },
    // setHumidity(index) {
    //   if (index == 1) {
    //     this.humidityValue = 40;
    //     this.myChart.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   } else if (index == 2) {
    //     this.humidityValue = 70;
    //     this.myChart2.setOption({
    //       title: [{ text: this.humidityValue }],
    //       series: [{ data: [{ value: this.humidityValue }] }],
    //     });
    //   }
    // },
    // updateHumidity() {},
  },
  watch: {
    Echartsdata: {
      handler() {
        this.initStates();
        console.log(this.Echartsdata, "echarts");
      },
      deep: true,

      // this.option.series[0].data = this.Echartsdata.y;
      // this.myChart5.setOption(this.option);
    },
  },
};
</script>

<style scoped lang="scss">
.echarts-container {
  position: relative;
}
.bar-echarts {
  // width: auto;
  //width: 300px;
  width: 100%;
  height: 100%;
  //height: 350px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 5px;
  background-color: #ffffff;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
}
.date-block {
  position: absolute;
  bottom: 1px;
  left: 30%;
}
</style>
