<template>
  <div class="chart-dialog">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="chartDialogShow"
      width="460px"
      center
      top="20vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="chart-dialog-title">
        <el-radio-group v-model="title" size="mini" @change="changeTitle">
          <template v-for="item in titleList">
            <el-radio-button :label="item" :key="item"></el-radio-button>
          </template>
        </el-radio-group>
      </div>
      <i class="el-icon-close close-icon" @click="closeChartDialog"></i>
      <div class="echarts-container" v-loading="chartProperty.loading">
        <div id="dialogChart"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <span>{{ footer }}</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ChartDialog",
  props: {
    chartProperty: {
      loading: false,
      show: false,
    },
    chartDialogShow: {
      type: Boolean,
      default: false,
    },
    chartData: {
      xArray: [],
      yArray: [],
    },
    footer: String,
  },
  data() {
    return {
      myDialogChart: {},
      title: "PM2.5",
      titleList: ["PM2.5", "CO2", "温度", "湿度", "TVOC", "甲醛"],
      dialogTitle: "PM2.5指数",
      option: null,
      timer: null,
    };
  },
  beforeDestroy() {
    //销毁echarts实例
    this.$echarts.dispose(this.myDialogChart);
  },
  methods: {
    initChart() {
      console.log(document.getElementById("dialogChart"));
      this.myDialogChart = this.$echarts.init(
        document.getElementById("dialogChart")
      );
      let option = (this.option = {
        xAxis: [
          {
            type: "category",
            data: this.chartData.xArray,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        grid: {
          top: "5%",
          bottom: "10%",
        },
        series: [
          {
            name: "直接访问",
            type: "bar",
            barWidth: "40%",
            data: this.chartData.yArray,
            itemStyle: {
              color: "#01cfa5",
              borderRadius: [15, 15, 0, 0],
            },
          },
        ],
      });
      this.myDialogChart.setOption(option);
    },
    closeChartDialog() {
      this.$emit("closeChartDialog");
    },
    changeTitle(e) {
      let timer = this.timer;
      if (timer !== null) {
        clearTimeout(timer);
      }
      this.dialogTitle = `${e} 指数`;
      const emitEven = () => {
        // console.log(this);
        this.$emit("changeTitle", e);
      };
      timer = setTimeout(emitEven, 800);
    },
    // debounce(fn, wait) {
    //   let timer = null;
    //   console.log(timer, "timer");
    //   return function () {
    //     if (timer !== null) {
    //       clearTimeout(timer);
    //     }
    //     timer = setTimeout(fn, wait);
    //   };
    // },
  },
  watch: {
    // chartDialogShow(newValue) {
    //   if (newValue) {
    //     this.initChart();
    //     // setTimeout(() => {
    //     //
    //     // }, 500);
    //     // this.$nextTick(() => {
    //     //
    //     // });
    //   }
    // },
    chartData: {
      handler() {
        // console.log(this.chartData);
        // if (this.chartData.xArray) {
        //   this.chartData.xArray = this.chartData.xArray.map((item) => {
        //     return item.slice(5, 10);
        //   });
        // } else {
        //   this.chartData.xArray = this.chartData.xAxisV.map((item) => {
        //     return item.slice(5, 10);
        //   });
        //   this.chartData.yArray = this.chartData.yAxisV;
        // }
        // console.log(this.chartData);
        this.$echarts.dispose(this.myDialogChart);
        // this.myDialogChart.setOption(this.option);
        // this.$nextTick(() => {
        //   // this.myDialogChart.setOption(this.option);
        this.initChart();
      },
      deep: true,
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.chart-dialog {
  ::v-deep .el-dialog {
    border-radius: 8px;
    .el-dialog__header {
      border-bottom: 1px solid #eaeaea;
    }
    .chart-dialog-title {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
    .el-dialog__body {
      padding-top: 20px;
    }
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 16px;
    &:hover {
      color: #409eff;
      cursor: pointer;
    }
  }

  .echarts-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    #dialogChart {
      width: 400px;
      height: 240px;
    }
  }
}
</style>
